import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import './scss/index.scss'
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import isEmpty from 'lodash/isEmpty';
import {BrowserRouter} from "react-router-dom";

Sentry.init({
  dsn: "https://4f8fa65373454c0eb2fe0e4d40b9582f@o144167.ingest.sentry.io/6778333",
  integrations: [
    new Sentry.Integrations.Breadcrumbs({ console: false }),
    new TracingIntegrations.BrowserTracing(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function initApp() {
  library.add(fas);

  import('./App.js').then((resp) => {
    const App = resp.default;
    ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
  });
}

// Init application only after translation is loaded
i18n.on('loaded', (loaded) => {
  if (!isEmpty(loaded)) {
    initApp();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
